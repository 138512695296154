import { Typography } from '@mui/material'
import { FC } from 'react'

const Home: FC = () => {
  return (
    <div>
      <Typography variant="h5">Canopy</Typography>
    </div>
  )
}

export default Home
